import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Text } from 'react-konva';
import Houses from './components/Houses'
import Planet from './components/Planet'
import Aspects from './components/Aspects'

const Chart = ({chart, size, triggerDownload, setTriggerDownload, text, filename}) => {
  const planets = chart
  const [sizing, setSizing] = useState({...defaultSizing, size})
  const [activePlanet, setActivePlanet] = useState(null)
  const stage = useRef()

  useEffect(() => {
    if (triggerDownload) {
      download()
    }
  }, [triggerDownload])

  const download = (originalSize) => {
    var link = document.createElement('a');
    link.download = `${filename || 'piscorpii-chart'}.png`;
    link.target = "_blank"
    link.href = stage.current.toDataURL({ pixelRatio: 1 });
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // setSizing({...sizing, size: initialSize})
    setTriggerDownload(false)
  }

  return (
    <Stage ref={stage} width={sizing.size} height={sizing.size}>

      <Houses size={sizing.size} ratio={sizing.outerRatio} chart={chart} />
      {text && <Layer>
        <Text
          text={text}
          x={size * .01}
          y={size * .01}
          fill={'#dddddd'}
          fontSize={size * .02}
        />
        <Text
          text={'piscorpii.com'}
          x={size * .01}
          y={size - size * .02}
          fill={'#dddddd'}
          opacity={.5}
          fontSize={size * .015}

        />
      </Layer>}
      <Layer>
        {planets.map(planet => (
          <Aspects
            key={planet.name}
            planet={planet}
            chartRadius={sizing.size / 2}
            distance={(sizing.size / 2) * sizing.outerRatio}
            visible={!activePlanet || activePlanet === planet.name}
            setActivePlanet={setActivePlanet}
          />
        ))}
      </Layer>
      <Layer>
        {planets.map(planet => (
          <Planet
            key={planet.name}
            planet={planet}
            chartRadius={sizing.size / 2}
            distance={(sizing.size / 2) * sizing.outerRatio}
            visible={!activePlanet || activePlanet === planet.name}
            setActivePlanet={setActivePlanet}
            showLabel={activePlanet === planet.name}
          />
        ))}
      </Layer>
    </Stage>
  );
}

export default Chart;

const defaultSizing = {
  size: 800,
  innerRatio: 0.5,
  outerRatio: 0.8,
}
