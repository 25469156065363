export const PLANETS = {
  Sun: {
    color: '#FF8000',
    aspects: [180],
    size: 0.8
  },
  Moon: {
    color: '#FFF',
    aspects: [180],
    size: 0.8
  },
  Mars: {
    color: '#FF4E4E',
    aspects: [180, 90, 210],
    size: 0.7
  },
  Mercury: {
    color: '#50BB57',
    aspects: [180],
    size: 0.6
  },
  Jupiter: {
    color: '#FFCF00',
    aspects: [180, 120, 240],
    size: 1
  },
  Venus: {
    color: '#00DEFF',
    aspects: [180],
    size: 0.65
  },
  Saturn: {
    color: '#976AFF',
    aspects: [180, 60, 270],
    size: 0.9
  },
  Rahu: {
    // color: '#1B1B1B',
    color: 'grey',
    aspects: [180, 90, 210],
    size: 0.8
  },
  Ketu: {
    // color: '#2E1600',
    color: 'lightgrey',
    aspects: [180, 90, 210],
    size: 0.8
  },
  Ascendant: {
    color: '#7BFF73',
    aspects: [180],
    size: 0.8
  }
}

export const HOUSES = [
  {
    id: 1,
    name: 'Aries',
    nameEn: 'Aries'
  },
  {
    id: 2,
    name: 'Tauro',
    nameEn: 'Taurus'
  },
  {
    id: 3,
    name: 'Géminis',
    nameEn: 'Gemini'
  },
  {
    id: 4,
    name: 'Cáncer',
    nameEn: 'Cancer'
  },
  {
    id: 5,
    name: 'Leo',
    nameEn: 'Leo'
  },
  {
    id: 6,
    name: 'Virgo',
    nameEn: 'Virgo'
  },
  {
    id: 7,
    name: 'Libra',
    nameEn: 'Libra'
  },
  {
    id: 8,
    name: 'Escorpio',
    nameEn: 'Scorpio'
  },
  {
    id: 9,
    name: 'Sagitario',
    nameEn: 'Sagittarius'
  },
  {
    id: 10,
    name: 'Capricornio',
    nameEn: 'Capricorn'
  },
  {
    id: 11,
    name: 'Acuario',
    nameEn: 'Aquarius'
  },
  {
    id: 12,
    name: 'Piscis',
    nameEn: 'Pisces'
  }
]

export const LANGUAGE = {
  es: {
    months: {
      1: 'Enero',
      2: 'Febrero',
      3: 'Marzo',
      4: 'Abril',
      5: 'Mayo',
      6: 'Junio',
      7: 'Julio',
      8: 'Agosto',
      9: 'Septiembre',
      10: 'Octubre',
      11: 'Noviembre',
      12: 'Diciembre',
    }
  }
}
