import React from 'react';
import ReactDOM from 'react-dom';
import './style/app.scss';
import App from './App/index'
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('UA-180609167-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
