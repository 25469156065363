import axios from "axios";

// import charts from '../mockData/charts.js'

export const fetchChart = (data) => {
  // console.log(data);
  const params = {
    api_key: process.env.REACT_APP_VEDIC_ASTRO_API_KEY,
    dob: `${data.day < 10 ? "0" + data.day : data.day}/${
      data.month < 10 ? "0" + data.month : data.month
    }/${data.year}`,
    tob: `${data.hour < 10 ? "0" + data.hour : data.hour}:${
      data.min < 10 ? "0" + data.min : data.min
    }`,
    tz: data.tz,
    lat: data.lat,
    lon: data.lon,
  };

  console.log("PARAMS: ", params);

  return axios
    .get("https://api.vedicastroapi.com/json/horoscope/vedic", { params })
    .then((response) => {
      console.log("RESPONSE: ", response);
      const planets = response.data.response;
      const chart = [
        planets[0],
        planets[1],
        planets[2],
        planets[3],
        planets[4],
        planets[5],
        planets[6],
        planets[7],
        planets[8],
        planets[9],
      ];
      return chart;
    })
    .catch((err) => {
      console.log("CHART ERROR: ", err);
      alert(
        "Se produjo un error al obtener los datos del planeta. Intentar otra vez."
      );
      throw err;
    });
};
