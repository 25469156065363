import React from 'react';
import { Layer, Wedge, Text, Label, Tag, Rect } from 'react-konva'
import { HOUSES } from '../../../constants'

const Houses = ({size, ratio, chart}) => {
  const houses = []
  const labels = []
  let userHouses = HOUSES.map(h => h.id)
  const asc = chart.find(c => c.name === 'As').zodiac
  const index = HOUSES.findIndex(h => h.nameEn === asc)

  if (index) {
    userHouses = userHouses.concat(userHouses.splice(0, userHouses.length - index))
  }

  HOUSES.forEach((house, i) => {
    const angle = house.id * 30 - 135
    const radius = size / 2
    const labelRadius = radius > 700 ? radius * .85 : radius * .8
    houses.push(
      <Wedge
        key={house.name}
        x={size / 2}
        y={size / 2}
        radius={(size / 2) * ratio}
        angle={30}
        rotation={((house.id) * 30) - 150}
        stroke="grey"
        strokeWidth={1}
        name={house.name}
        onClick={() => console.log(house.name)}
      />
    )
    labels.push(<Label
      key={`text-${house.name}`}
      x={radius + (labelRadius * Math.cos((angle * Math.PI) / 180))}
      y={radius + (labelRadius * Math.sin((angle * Math.PI) / 180))}
      rotation={angle + 90}
    >
      <Tag
        fill={'transparent'}
        pointerDirection={'down'}
        pointerWidth={20}
        pointerHeight={20}
      />
      <Text
        text={`${userHouses[i]}. ${house.name}`}
        fill={'grey'}
        align={'center'}
        fontSize={size * .02}
        verticalAlign={'top'}
      />
    </Label>)
  })

  return (
    <Layer>
      <Rect width={size} height={size} fill={'#232329'} />
      {houses}
      {labels}
    </Layer>
  )
}


export default Houses;

// x={(size / 2) + (360 * Math.cos((((house.id * 30) - 140) * Math.PI) / 180))}
// y={(size / 2) + (360 * Math.sin((((house.id * 30) - 140) * Math.PI) / 180))}
