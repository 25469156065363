import React from 'react';
// import DatePicker from '../DatePicker'
import Select from 'react-select'
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
// import axios from 'axios'

const InputForm = ({form, setForm, submitChart, fetching}) => {

  const handlePlaceChange = (p) => {
    setForm({...form, place: p})
  }

  const handlePlaceSelect = async (p) => {
    const geoCode = await geocodeByAddress(p)
    const latLng = await getLatLng(geoCode[0])
    const place = {
      place: p,
      lat: latLng.lat,
      lon: latLng.lng,
    }
    setForm({...form, ...place})
  }

  return (
    <div className="input-container">
      <div className="input-row full-row">
      <PlacesAutocomplete
          value={form.place}
          onChange={handlePlaceChange}
          onSelect={handlePlaceSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="places-input-container">
              <input
                {...getInputProps({
                  placeholder: 'Ubicación ...',
                  className: 'material-form__field',
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, i) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#38373f', cursor: 'pointer' }
                    : { backgroundColor: '#2a2a31', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={suggestion.placeId}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <div className="input-row">
        <Select
          className="input-select"
          options={months}
          placeholder="Mes"
          defaultValue={form.month && months.find(obj => obj.value === form.month)}
          styles={styles}
          onChange={v => setForm({...form, month: v.value})}
        />
        <Select
          className="input-select"
          options={days}
          placeholder="Día"
          defaultValue={form.day && days.find(obj => obj.value === form.day)}
          styles={styles}
          onChange={v => setForm({...form, day: v.value})}
        />
        <Select
          className="input-select"
          options={years}
          placeholder="Año"
          defaultValue={form.year && years.find(obj => obj.value === form.year)}
          styles={styles}
          onChange={v => setForm({...form, year: v.value})}
        />
      </div>
      <div className="input-row">
        <Select
          className="input-select"
          options={hours}
          placeholder="Hora"
          defaultValue={form.hour && hours.find(obj => obj.value === form.hour)}
          styles={styles}
          onChange={v => setForm({...form, hour: v.value})}
        />
        <Select
          className="input-select"
          options={minutes}
          placeholder="Minuto"
          defaultValue={form.min && minutes.find(obj => obj.value === form.min)}
          styles={styles}
          onChange={v => setForm({...form, min: v.value})}
        />
        <Select
          className="input-select"
          options={ampm}
          placeholder="AM/PM"
          defaultValue={form.ampm && ampm.find(obj => obj.value === form.ampm)}
          styles={styles}
          onChange={v => setForm({...form, ampm: v.value})}
        />
      </div>
      <div className="input-row submit-row">
        <button
          className="btn"
          onClick={() => submitChart()}
          disabled={!form.lat || !form.lon || !form.month || !form.day || !form.year || !form.hour || isNaN(form.min) || !form.ampm || fetching}
        >
        Obtener gráfico
        </button>
      </div>
    </div>
  );
}



export default InputForm;

const range = (start, end) => Array.from({length: (end + 1 - start)}, (v, k) => k + start);

const years = range(1900, new Date().getFullYear() + 2).map(year => ({value: year, label: year})).reverse()
const months = [
  {value: 1, label: 'Enero'},
  {value: 2, label: 'Febrero'},
  {value: 3, label: 'Marzo'},
  {value: 4, label: 'Abril'},
  {value: 5, label: 'Mayo'},
  {value: 6, label: 'Junio'},
  {value: 7, label: 'Julio'},
  {value: 8, label: 'Agosto'},
  {value: 9, label: 'Septiembre'},
  {value: 10, label: 'Octubre'},
  {value: 11, label: 'Noviembre'},
  {value: 12, label: 'Diciembre'},
]

const days = range(1, 31).map(day => ({value: day, label: day}))

const hours = range(1, 12).map(hour => {
  return {
    value: hour,
    label: hour
  }
})

const minutes = range(0, 59).map(minutes => {
  return {
    value: minutes,
    label: minutes < 10 ? `0${minutes}` : minutes
  }
})

const ampm = [{value: 'am', label: 'AM'}, {value: 'pm', label: 'PM'}]

const styles = {
  input: (provided, state) => {
    return {
      color: '#dddddd'
    }
  },
  singleValue: (provided, state) => {
    return {
      background: '#2a2a31',
      color: '#dddddd'
    }
  },
  control: (provided, state) => {
    return {
      ...provided,
      background: '#2a2a31',
      borderColor: '#333246',
    }
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      color: '#999999'
    }
  },
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      color: '#999999'
    }
  },
  indicatorSeparator: (provided, state) => {
    return {
      ...provided,
      backgroundColor: '#999999'
    }
  },
  menu: (provided, state) => {
    return {
      ...provided,
      background: '#2a2a31',
      color: '#dddddd'
    }
  },
  option: (provided, state) => {
    return {
      ...provided,
      color: state.isFocused ? '#38373f' : '#dddddd',
      backgroundColor: state.isFocused ? '#dddddd' : '#2a2a31',
    }
  },
}
