import React, { useState, useEffect, useRef } from "react";
import logo from "../logo.svg";
import InputForm from "../components/InputForm";

import { MdEdit } from "react-icons/md";
import { MdFileDownload } from "react-icons/md";
import { LANGUAGE } from "../constants";

import { fetchChart } from "../api/vedicAstroApi.js";
import { fetchTimezone } from "../api/google.js";

// import charts from '../mockData/charts.js'

import Chart from "../components/Chart";
import PlanetTable from "../components/PlanetTable";

const App = () => {
  const [form, setForm] = useState({ ...emptyForm });
  const [chart, setChart] = useState(null);
  const [size, setSize] = useState(0);
  const [showForm, setShowForm] = useState(true);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [fetching, setFetching] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setSize(ref.current.clientWidth);
  }, []);

  const submitChart = async (e) => {
    setFetching(true);
    const data = { ...form };
    if (form.ampm === "pm" && form.hour < 12) {
      data.hour += 12;
    } else if (form.ampm === "am" && form.hour === 12) {
      data.hour = 0;
    }
    const tz = await fetchTimezone(data);
    data.tz = tz;

    try {
      const chart = await fetchChart(data);
      setChart(chart);
      setShowForm(false);
      setFetching(false);
    } catch (err) {
      console.log("FETCH CHART ERROR: ", err);
      setFetching(false);
    }
  };

  const getText = () => {
    return `${form.place} - ${LANGUAGE.es.months[form.month]} ${form.day}, ${
      form.year
    } - ${form.hour}:${form.min < 10 ? "0" + form.min : form.min} ${
      form.ampm === "am" ? "AM" : "PM"
    }`;
  };

  const getFilename = () => {
    return `piscorpii-chart-${form.place}-${LANGUAGE.es.months[form.month]}-${
      form.day
    }-${form.year}-${form.hour}_${form.min < 10 ? "0" + form.min : form.min}-${
      form.ampm === "am" ? "AM" : "PM"
    }`;
  };

  return (
    <div className="container home-container">
      <header className="app-header">
        <div className="header-logo">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div className="header-titles">
          <h1>Piscorpii</h1>
          <h3>Védica Visualizada</h3>
        </div>
      </header>
      {showForm ? (
        <InputForm
          form={form}
          setForm={setForm}
          submitChart={submitChart}
          fetching={fetching}
        />
      ) : (
        <div>
          <div className="chart-title-container">
            <h2>{form.place}</h2>
            <h4>{`${LANGUAGE.es.months[form.month]} ${form.day} ${form.year} ${
              form.hour
            }:${form.min < 10 ? "0" + form.min : form.min} ${
              form.ampm === "am" ? "AM" : "PM"
            }`}</h4>
            <div className="chart-title-buttons">
              <button className="btn" onClick={() => setShowForm(true)}>
                <MdEdit size={20} />
              </button>
              <button className="btn" onClick={() => setTriggerDownload(true)}>
                <MdFileDownload size={20} />
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="graphics-container">
        <div ref={ref} className="chart-container">
          {chart && size && <Chart chart={chart} size={size} />}
        </div>
        <div className="table-container">
          {chart && size && <PlanetTable planets={chart} size={size} />}
        </div>
      </div>
      <div style={{ display: "none" }}>
        {chart && triggerDownload && (
          <Chart
            chart={chart}
            size={1500}
            triggerDownload={triggerDownload}
            setTriggerDownload={setTriggerDownload}
            text={getText()}
            filename={getFilename()}
          />
        )}
      </div>
    </div>
  );
};

export default App;

let date = new Date();
const now = {
  day: date.getDate(),
  month: date.getMonth() + 1,
  year: date.getFullYear(),
  hour: date.getHours() < 13 ? date.getHours() : date.getHours() - 12,
  min: date.getMinutes(),
  ampm: date.getHours() < 12 ? "am" : "pm",
};

// const paris = {
//   lat: 48.856614,
//   lon: 2.3522219,
//   tz: 2,
//   place: "Paris, France"
// }

const emptyForm = {
  year: null,
  month: null,
  day: null,
  hour: null,
  min: null,
  ampm: null,
  lat: null,
  lon: null,
  tz: null,
  place: "",
  ...now,
  // ...paris
};
