import React from 'react';
import { Group, Circle, Rect, Text, Label, Tag } from 'react-konva'
import { PLANETS } from '../../../constants'

const Planet = ({planet, chartRadius, distance, visible, setActivePlanet, showLabel}) => {
  const circleRatio = distance * .07
  const rectRatio = distance * .13

  const p = planet
  const size = PLANETS[p.full_name].size
  const color = PLANETS[p.full_name].color
  // const aspects = PLANETS[p.full_name].aspects
  const angle = planet.global_degree - 120
  const radius = chartRadius

  const x = radius + (distance * Math.cos((angle * Math.PI) / 180))
  const y = radius + (distance * Math.sin((angle * Math.PI) / 180))

  return (
    <Group
      visible={visible}
      onMouseOver={()=> setActivePlanet(p.name)}
      onMouseOut={()=> setActivePlanet(null)}
      onMouseDown={()=> setActivePlanet(p.name)}
      onMouseUp={()=> setActivePlanet(null)}
      onTouchStart={()=> setActivePlanet(p.name)}
      onTouchEnd={()=> setActivePlanet(null)}
    >
      {planet.retro ?
        <Rect
          x={x}
          y={y}
          width={rectRatio * size}
          height={rectRatio * size}
          offsetX={rectRatio * size / 2}
          offsetY={rectRatio * size / 2}
          fill={color}
          rotation={angle}
          onClick={() => console.log(p.name)}
        />
        : planet.full_name === 'Ascendant' ?
        <Circle
          x={x}
          y={y}
          radius={circleRatio * size}
          rotation={angle}
          onClick={() => console.log(p.name)}
          fillLinearGradientStartPoint= {{ x: 0, y: -25 }}
          fillLinearGradientEndPoint={{ x: 0, y: 0 }}
          fillLinearGradientColorStops= {[0, 'transparent', 1, color]}
        />
        :
        <Circle
          x={x}
          y={y}
          radius={circleRatio * size}
          fill={color}
          onClick={() => console.log(p.name)}
        />
      }
      {showLabel && <Label
        key={planet.name}
        x={x}
        y={y + 10 - circleRatio * size}
      >
        <Tag
          fill={'transparent'}
          pointerDirection={'down'}
          pointerWidth={20}
          pointerHeight={20}
        />
        <Text
          text={planet.full_name}
          fill={color}
          align={'center'}
          fontStyle="bold"
        />
      </Label>}
    </Group>
  )
}

export default Planet;

// <Circle
//   x={coord[0]}
//   y={coord[1]}
//   radius={18 * size * .5}
//   stroke={color}
//   strokeWidth={1}
//   onClick={() => console.log(p.name)}
// />

// const rotatePoints = (cx, cy, x, y, angle) => {
//   var radians = (Math.PI / 180) * angle,
//       cos = Math.cos(radians),
//       sin = Math.sin(radians),
//       nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
//       ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
//   return [nx, ny];
// }

// export const PLANETS = {
//   Sun: {
//     color: '#FF8000',
//     aspects: [180],
//     size: 0.8
//   },
//   Moon: {
//     color: '#FFF',
//     aspects: [180],
//     size: 0.8
//   },
//   Mars: {
//     color: '#FF4E4E',
//     aspects: [180, 90, 210],
//     size: 0.7
//   },
//   Mercury: {
//     color: '#50BB57',
//     aspects: [180],
//     size: 0.6
//   },
//   Jupiter: {
//     color: '#FFCF00',
//     aspects: [180, 120, 240],
//     size: 1
//   },
//   Venus: {
//     color: '#00DEFF',
//     aspects: [180],
//     size: 0.65
//   },
//   Saturn: {
//     color: '#976AFF',
//     aspects: [180, 60, 270],
//     size: 0.9
//   },
//   Rahu: {
//     // color: '#1B1B1B',
//     color: 'grey',
//     aspects: [180, 90, 210],
//     size: 0.8
//   },
//   Ketu: {
//     // color: '#2E1600',
//     color: 'lightgrey',
//     aspects: [180, 90, 210],
//     size: 0.8
//   },
//   Ascendant: {
//     color: '#7BFF73',
//     aspects: [180],
//     size: 0.8
//   }
// }
