import axios from "axios";

export const fetchTimezone = (data) => {
  return axios
    .get("https://maps.googleapis.com/maps/api/timezone/json", {
      params: {
        location: `${data.lat},${data.lon}`,
        timestamp:
          new Date(`${data.year}/${data.month}/${data.day}`).getTime() / 1000,
        key: process.env.REACT_APP_GOOGLE_API_KEY,
      },
    })
    .then((response) => {
      const timezone = response.data;
      const tz = timezone.rawOffset / 3600 + timezone.dstOffset / 3600;
      return tz;
    })
    .catch((err) => console.log("GOOGLE TZ ERROR: ", err, err.response));
};
