import React from 'react';
import { Group, Line, Arrow } from 'react-konva'
import { PLANETS } from '../../../constants'

const Aspects = ({planet, chartRadius, distance, visible, setActivePlanet}) => {
  const p = planet
  // const size = PLANETS[p.full_name].size
  const color = PLANETS[p.full_name].color
  const aspects = PLANETS[p.full_name].aspects
  const angle = planet.global_degree - 120
  const radius = chartRadius

  const pointerSize = distance * .04

  const x = radius + (distance * Math.cos((angle * Math.PI) / 180))
  const y = radius + (distance * Math.sin((angle * Math.PI) / 180))

  const opacity = 1
  const dash =[1,5]

  return (
    <Group
      visible={visible}
    >
      <Line
        points={[x,y, radius, radius]}
        stroke={color}
        strokeWidth={.5}
        dash={dash}
        opacity={opacity}
      />
      {aspects.map((a, i) => {
        const coord = rotatePoints(radius, radius, x, y, -a)
        return (

            <Arrow
              key={planet.name + i}
              points={[radius, radius, ...coord]}
              stroke={color}
              fill={color}
              strokeWidth={.5}
              dash={dash}
              opacity={opacity}
              pointerLength={pointerSize}
              pointerWidth={pointerSize}
            />


        )
      })}
    </Group>
  )
}

export default Aspects;

// <Circle
//   x={coord[0]}
//   y={coord[1]}
//   radius={18 * size * .5}
//   stroke={color}
//   strokeWidth={1}
//   onClick={() => console.log(p.name)}
// />

const rotatePoints = (cx, cy, x, y, angle) => {
  var radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
      ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
  return [nx, ny];
}
