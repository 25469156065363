import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { BsSquareFill } from "react-icons/bs";

import { PLANETS } from '../../constants'
const PlanetTable = ({planets}) => {
  const obj = {}
  planets.forEach(p => obj[p.name] = p.full_name)
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>House</Th>
          <Th>Sign</Th>
          <Th>Retro</Th>
          <Th>Nakshatra</Th>
          <Th>Nakshatra Lord</Th>
          <Th>Pad</Th>
        </Tr>
      </Thead>
      <Tbody>
        {planets.sort((a,b) => a.house - b.house).map(p => (
          <Tr key={p.name}>
            <Td style={{color: PLANETS[p.full_name].color}}>{p.full_name}</Td>
            <Td>{p.house}</Td>
            <Td>{p.zodiac}</Td>
            <Td style={{paddingLeft: '.55rem'}}>{p.retro ? <BsSquareFill size={10}/> : ''}</Td>
            <Td>{p.nakshatra_no}: {p.nakshatra}</Td>
            <Td>{obj[p.nakshatra_lord]}</Td>
            <Td>{p.nakshatra_pada}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}


export default PlanetTable;
